import React, { Fragment } from "react";
import AppRouter from "./routers/AppRouter";

function App() {
  console.log(process.env.REACT_APP_URL_API);
  return (
    <Fragment>
      <AppRouter />
    </Fragment>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Message from "../Components/Message";
import Footer from "../Components/Footer";
import bacgroundHeader from "../Assets/backGround.jpg";
import logoIpc from "../Assets/Logo_IPC_Blue.svg";

const Home = () => {
  const [colaboradores, setColaboradores] = useState([]);
  const [loadedData, setLoadedData] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/colaboradores`).then((res) => {
      setColaboradores(res.data);
      setLoadedData(true);
    });
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const url = "/firma/";
  const urlAlternativa = "/firmaalternativa/";
  const urlAdmin = "/admin";

  console.log(process.env.REACT_APP_URL_API);

  return (
    <>
      <div
        style={{
          width: "100%",
          margin: "0 auto",
          padding: "0",
          fontFamily: "Calibri, Gill Sans",
          backgroundImage: `url(${bacgroundHeader})`,
          backgroundSize: "contain",
        }}
      >
        <div
          style={{
            width: "70%",
            margin: "0 auto 80px auto",
            display: "flex",
            padding: "20px 0",
          }}
        >
          <img
            src={logoIpc}
            style={{
              width: "50px",
              borderRight: "1px solid #fff",
              padding: "0 20px 0 0",
            }}
            alt="Piscinas IPC"
          ></img>
          <h2
            style={{
              color: "#FFF",
              fontFamily: "Calibri, Gill Sans",
              padding: "0 0 0 20px",
            }}
          >
            Generador de Firmas
          </h2>
        </div>

        <h1
          style={{
            width: "30%",
            margin: "0 auto",
            color: "#FFF",
            fontFamily: "Calibri, Gill Sans",
            textAlign: "center",
          }}
        >
          A continuación vas a encontrar tu firma.
        </h1>
        <div
          style={{
            padding: "10px 0 20px 0",
            textAlign: "center",
            borderBottom: "1px solid #e3e3e3",
          }}
        >
          <p style={{ margin: "10px 0 40px 0", color: "#fff" }}>
            Ingresá el nombre de la persona y seleccioná la opción de firma que
            deseás incluir.
          </p>
          <input
            type="text"
            name="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Buscar"
            style={{
              color: "#555",
              backgroundColor: "#fff",
              border: "2px solid #E6E6E6",
              borderRadius: "96px",
              padding: "13px 15px",
              width: "60%",
              fontSize: "18px",
            }}
          />
        </div>

        <div
          style={{
            backgroundColor: "#FAFBFF",
            padding: "80px 0",
            margin: "0px 0 0 0 ",
            display: "flex",
          }}
        >
          <div style={{ width: "70%", margin: "0 auto" }}>
            <h1>
              Colaboradores
              <span>
                <a
                  href="https://admin.piscinasipc.com/uploads/Manual_firmas_IPC_2e22728db3.pdf"
                  target="_blank"
                  style={{
                    fontSize: "14px",
                    padding: "15px 25px",
                    textDecoration: "none",

                    float: "right",
                    background:
                      "linear-gradient(180deg, #0071E3 7.14%, #0E83E1 107.14%)",
                    borderRadius: "8px",
                    color: "#fff",
                  }}
                >
                  Como configurar tu firma
                </a>
              </span>
            </h1>
            <div style={{ margin: "50px 0 0 0" }}>
              {colaboradores
                .filter((colaborador) =>
                  colaborador.Nombre.match(new RegExp(searchValue, "i"))
                )
                .map((colaborador) => {
                  return (
                    <div
                      key={colaborador.id}
                      style={{
                        backgroundColor: "#fff",
                        width: "31%",
                        float: "left",
                        margin: "0 2% 20px 0",
                        borderRadius: "24px",
                        boxShadow:
                          "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                      }}
                    >
                      <div style={{ padding: "40px 30px" }}>
                        <h3
                          style={{
                            fontSize: "24px",
                            margin: "0",
                            padding: "0",
                            fontWeight: "bold",
                          }}
                        >
                          {colaborador.Nombre}{" "}
                        </h3>
                        <h5
                          style={{
                            fontSize: "16px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {colaborador.Puesto}{" "}
                        </h5>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FAFBFF",
                          padding: "10px 30px",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <p>Modelo 01</p>
                        </div>
                        <div style={{ width: "50%" }}>
                          <a
                            href={url + colaborador.slug}
                            target="_blank"
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            <p
                              style={{
                                width: "100px",
                                backgroundColor: "#0071e3",
                                textAlign: "center",
                                borderRadius: "12px",
                                padding: "10px",

                                margin: "0 10px 0 0",
                              }}
                            >
                              Descargar
                            </p>
                          </a>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "10px 30px",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <p>Modelo 02</p>
                        </div>
                        <div style={{ width: "50%" }}>
                          <a
                            href={urlAlternativa + colaborador.slug}
                            target="_blank"
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            <p
                              style={{
                                width: "100px",
                                backgroundColor: "#0071e3",
                                textAlign: "center",
                                borderRadius: "12px",
                                padding: "10px",

                                margin: "0 10px 0 0",
                              }}
                            >
                              Descargar
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Message></Message>
      <Footer></Footer>
    </>
  );
};

export default Home;

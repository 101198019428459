import React from "react";
import logoIpc from "../Assets/Logo_IPC_Blue.svg";
import powered from "../Assets/Powered.svg";

const Footer = () => {
  return (
    <div style={{ width: "90%", display: "flex", padding: "20px 1%" }}>
      <div style={{ width: "40%" }}>
        <p
          style={{
            fontFamily: "Calibri, Gill Sans",
            fontSize: "13px",
            color: "#032139",
          }}
        >
          Copyright © 2022 Piscinas Premium S.A. Todos los Derechos Reservados.
        </p>
      </div>
      <div style={{ width: "20%", textAlign: "center" }}>
        <img
          src={logoIpc}
          style={{
            width: "30px",
          }}
          alt="Piscinas IPC"
        ></img>
      </div>
      <div style={{ width: "40%", textAlign: "right" }}>
        <p>
          {" "}
          <img src={powered} alt="Powered by Possumus"></img>
        </p>
      </div>
    </div>
  );
};

export default Footer;

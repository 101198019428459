import React from "react";

const Message = () => {
  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        backgroundColor: "#054172",
        textAlign: "center",
        padding: "1px 0",
      }}
    >
      <p
        style={{
          fontFamily: "Calibri, Gill Sans",
          fontWeight: "500",
          fontSize: "18px",
          color: "#fff",
          padding: "#000",
        }}
      >
        Si no encuentras tu firma o algún dato es incorrecto, por favor ponte en
        contacto con nosotros.
      </p>
    </div>
  );
};

export default Message;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Firma from "../Pages/firma";
import FirmaAlternativa from "../Pages/firmaAlternativa";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/firma/:slug" element={<Firma />} />
        <Route
          exact
          path="/firmaalternativa/:slug"
          element={<FirmaAlternativa />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;

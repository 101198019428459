import React, { useEffect, useState } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";

const Firma = ({ match }) => {
  const [colaboradores, setColaboradores] = useState([]);
  const [imagenColaboradores, setImagenColaboradores] = useState([]);

  //   const idColaborador = match.params.id;

  const { slug } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/colaboradores/${slug}`)
      .then((res) => {
        setColaboradores(res.data);
        setImagenColaboradores(res.data.Imagen);
      });
  }, []);
  const urlImagen = process.env.REACT_APP_URL_API;
  console.log(imagenColaboradores.url);
  return (
    <table
      border="0"
      cellpadding="0"
      cellspacing="10"
      style={{
        width: "600px",
      }}
    >
      <thead>
        <tr>
          <td rowSpan="6">
            <img
              src={urlImagen + imagenColaboradores.url}
              style={{ width: "300px" }}
              alt="IPC"
            ></img>
          </td>
        </tr>
        <tr>
          <td>
            <table border="0" cellpadding="1" cellspacing="0">
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://admin.piscinasipc.com/uploads/person_a951db5e1e.png"
                      alt="Nombre"
                    ></img>
                  </td>
                  <td>
                    <h1
                      style={{
                        fontWeight: "bold",
                        color: "#002856",
                        margin: "0",
                        padding: "0",
                        fontSize: "28px",
                        fontFamily: "Calibri, Gill Sans",
                      }}
                    >
                      {colaboradores.Nombre}
                    </h1>
                    <h2
                      style={{
                        fontWeight: "100",
                        color: "#a8a8a8",
                        margin: "0",
                        padding: "0",
                        fontSize: "16px",
                        fontFamily: "Calibri, Gill Sans",
                        textTransform: "uppercase",
                      }}
                    >
                      {colaboradores.Puesto}
                    </h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table border="0" cellpadding="5" cellspacing="0">
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://admin.piscinasipc.com/uploads/mail_da07ff9ceb.png"
                      alt="Email"
                    ></img>
                  </td>
                  <td>
                    <p
                      style={{
                        color: "#575756",
                        margin: "0",
                        padding: "0",
                        fontSize: "16px",
                        fontFamily: "Calibri, Gill Sans",
                        fontWeight: "bold",
                      }}
                    >
                      {colaboradores.Email}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table border="0" cellpadding="5" cellspacing="0">
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://admin.piscinasipc.com/uploads/phone_860e014d16.png"
                      alt="Telefono"
                    ></img>
                  </td>

                  <td>
                    <p
                      style={{
                        color: "#575756",
                        margin: "0",
                        padding: "0",
                        fontSize: "16px",
                        fontFamily: "Calibri, Gill Sans",
                        fontWeight: "bold",
                      }}
                    >
                      {colaboradores.telefono}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table border="0" cellpadding="1" cellspacing="0">
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://admin.piscinasipc.com/uploads/map_Marker_f51a040d7c.png"
                      alt="Direccion"
                    ></img>
                  </td>
                  <td>
                    <p
                      style={{
                        color: "#575756",
                        margin: "0",
                        padding: "0",
                        fontSize: "16px",
                        fontFamily: "Calibri, Gill Sans",
                        fontWeight: "bold",
                      }}
                    >
                      {colaboradores.Direccion}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table border="0" cellpadding="5" cellspacing="0">
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.facebook.com/IPCPools.CasaCentral"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src="https://admin.piscinasipc.com/uploads/face_f05f27a26e.png"
                        alt="Facebook"
                        style={{ width: "24px" }}
                      ></img>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://www.instagram.com/piscinasipc/?hl=es-la"
                      target="_blank"
                    >
                      <img
                        src="https://admin.piscinasipc.com/uploads/Instagram_84a58754be.png"
                        alt="Instagram"
                        style={{ width: "24px" }}
                      ></img>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://www.youtube.com/user/piscinaspremiumipc"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src="https://admin.piscinasipc.com/uploads/youtube_dd0f29b406.png"
                        alt="Youtube"
                        style={{ width: "24px" }}
                      ></img>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </thead>
    </table>
  );
};

export default Firma;
